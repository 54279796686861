<template xmlns:add-medical-health-tamplate="http://www.w3.org/1999/html">
  <div>
    <v-row class="mx-2">
      <v-col cols="12" >
        <v-card flat style="border-radius: 12px" elevation="6">
          <v-card-title class="elevation-10">
            <v-row>
              <v-col class="d-flex align-center justify-start" cols="8"
              ><h5 class="d-flex align-left">{{ $t("mental-health-template") }}</h5>
              </v-col>
              <v-col cols="4">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('search-medical-template-medical-health')"
                    single-line
                    hide-details
                    filled
                    dense
                    background-color="#E8E8E8"
                    rounded
                    class="table-search-button"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :no-data-text="$t('no-data-available')"
              :loading-text="$t('loading')"
              :headers-length="headers.length"
              :search="search"
              :items="templates"
              :items-per-page="-1"
              fixed-header
              dense
              style="cursor: pointer"
              class="elevation-15"
              :height="testHeight"
              :single-expand="true"
              :expanded.sync="expanded"
              ref="table"
              :footer-props="{
          itemsPerPageText: this.$t('row'),
          itemsPerPageAllText: this.$t('all'),
        }"
          >
<!--            <template v-slot:[`group.header`]="{ group, headers, items, toggle }">-->
<!--              <td @click="toggle" style="vertical-align: !important; text-align: left !important;" :colspan="headers.length + 1">-->
<!--                <span style="font-weight: bold">{{group}}</span>-->
<!--                <v-chip pill x-small color="primary" class="ma-2 text&#45;&#45;black">{{ items.length }}</v-chip>-->
<!--                <span style="float: right;" class="mt-2">-->
<!--									<v-icon color="primary" v-if="isOpen" >mdi-arrow-down-drop-circle</v-icon>-->
<!--									<v-icon color="primary" v-else >mdi-arrow-up-drop-circle</v-icon>-->
<!--								</span>-->
<!--              </td>-->
<!--            </template>-->
            <template v-slot:[`group.header`]="{ group,headers,toggle,isOpen,items, }">
              <td @click="toggle" style="background-color:white;" class="elevation-3" :colspan="headers.length">
                <v-row class="align-center">
                  <v-col cols="3" class="text-left">
                    <span style="font-weight: bold">{{group}}</span>
                  </v-col>
                  <v-col cols="1">
                    <v-chip pill x-small color="primary" class="text--black">{{ items.length }}</v-chip>
                  </v-col>
                  <v-col cols="8" class="text-right">
                  <span>
                    <v-btn  small icon :ref="group" :data-open="isOpen">
                      <v-icon v-if="isOpen" color="primary">mdi-arrow-up-drop-circle</v-icon>
                      <v-icon v-else color="primary">mdi-arrow-down-drop-circle</v-icon>
                    </v-btn>
                  </span>
                  </v-col>
                </v-row>

              </td>
              <v-spacer></v-spacer>
            </template>
            <template v-slot:item="{item}">
              <tr  @click="editSurveyTemplate(item)">
                <td  class="table-cols">{{ item.templateName }}</td>
                <td  class="table-cols">{{ item.templateDescription }}</td>
                <td  class="table-cols">{{ item.createdByFullname }}</td>
                <td  class="table-cols-right">
                  <v-icon  @click="editSurveyTemplate(item)" color="primary">mdi-pencil</v-icon>
                  <v-icon @click.stop="showDeleteConfirmationDialog(item)" color="primary">mdi-delete</v-icon>
                </td>
              </tr>
            </template>
            <template v-slot:[`footer.prepend`]>
              <v-btn
                  v-if="roleName === 'DOCTOR' || roleName === 'NURSE'"
                  class="table-create-button"
                  label="AddDialog"
                  @click="createSurveyTemplate"
              >
                <img class="pt-1" height="20px" width="20px" :src="medical_health_white" style="margin-right:2%; margin-bottom: 5%;"/>
                <span style="text-transform: none;">{{ $t("addMentalHealtTemplate") }}</span>
              </v-btn>
            </template>
            <!-- <template v-if="roleName === 'HOSPITAL_ADMIN'" v-slot:[`footer.prepend`]="{}">
              <v-btn
                class="table-create-button"
                label="AddDialog"
                @click="manageMedicalTeamDialog = true"
              >
                <span>{{ $t("addNewMedicalTeam") }}</span>
              </v-btn>
            </template> -->
          </v-data-table>
        </v-card>
        <v-dialog v-model="deleteConfirmationDialog" max-width="600px" persistent>
          <v-card flat style="overflow: auto">
            <v-card-title class="secondary py-1" style="height: 40px">{{ $t("remove-template") }}</v-card-title>
            <v-card-text class="d-flex mt-2">
              {{ $t("confirm-deletion") }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" dense @click="deleteSurveyTemplateAndCloseDialog">{{ $t("yes") }}</v-btn>
              <v-btn color="primary" dense @click="closeDeleteConfirmationDialog">{{ $t("cancel") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <add-medical-health-tamplate @close="manageMentalHealthDialog=false" @update-survey="editSurveyTemplate(itemForEdit)" @refresh-surveys="loadData" :surveyAnswerType="surveyAnswerType" :mentalHealthCategory="mentalHealthCategory" :update="update" :dialog="manageMentalHealthDialog"></add-medical-health-tamplate>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { pencilImg, mentalHealthWhite } from '@/assets';
import AddMedicalHealthTamplate from '@/views/dialogs/AddMedicalHealthTamplate';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';
export default {
	components: {
		AddMedicalHealthTamplate,
	},
	data () {
		return {
			deleteConfirmationDialog: false,
			templateToDelete: null,
			expanded: [],
			mentalHealthCategory: [],
			surveyAnswerType: [],
			itemForEdit: {},
			search: '',
			pencilImg: pencilImg,
			update: false,
			patientId: null,
			manageMentalHealthDialog: false,
			medical_health_white: mentalHealthWhite,
		};
	},
	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
			surveyTemplatesFromRepo: (state) => state.mentalhealth.surveyTemplates,
			verifires: (state) => state.verifiers.verifiers,
		}),
		...mapGetters({
			roleName: 'authentication/getRole',
			hospitalId: 'authentication/getHospitalId',
			myMedicalTeam: 'authentication/getMyMedicalTeam',
			pageHeight: 'pageHeight',
			delay: 'authentication/getDelay',
		}),
		testHeight () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs':
				return window.innerHeight - 320;
			case 'sm':
				return window.innerHeight - 300;
			case 'md':
				return window.innerHeight - 300;
			case 'lg':
				return window.innerHeight - 280;
			case 'xl':
				return window.innerHeight - 280;
			default:
				return 0;
			}
		},
		templates () {
			if (this.surveyTemplatesFromRepo) {
				return this.surveyTemplatesFromRepo;
			} else {
				return [];
			}
		},
		headers () {
			return [
				{
					text: this.$t('name'),
					value: 'templateName',
					sortable: true,
					width: '15%',
				},
				{
					text: this.$t('description'),
					value: 'templateDescription',
					sortable: true,
					width: '60%',
				},
				{
					text: this.$t('created-by'),
					value: 'createdByFullname',
					sortable: true,
					width: '16%',
				},
				{
					align: 'start',
					sortable: false,
				},
				// <!-- JUST FOR SAKE OF SEARCHING THE HEADER -->
				// {
				// 	text: '',
				// 	sortable: false,
				// 	value: 'patientFullname',
				// 	class: 'text-end black_inner--text !important',
				// },
				// <!-- END OF JUST FOR SAKE OF SEARCHING THE HEADER -->
			];
		},
	},
	watch: {
		async manageMentalHealthDialog () {
			const header = { domainsList: 'MENTAL_HEALTH_CATEGORY,SURVEY_ANSWER_TYPE' };
			await this.$store.dispatch('verifiers/getVerifiersForMultipleDomains', header).then(() => {
				this.mentalHealthCategory = [];
				this.surveyAnswerType = [];

				this.verifires.forEach(item => {
					if (item.rvDomain === 'MENTAL_HEALTH_CATEGORY') {
						this.mentalHealthCategory.push(item);
					} else if (item.rvDomain === 'SURVEY_ANSWER_TYPE') {
						// remove number range
						if (item.lowValue !== 4) { this.surveyAnswerType.push(item); }
					}
				});
				this.surveyCreate = true;
			});
		},
	},
	methods: {
		closeAll () {
			Object.keys(this.$refs).forEach(k => {
				if (this.$refs[k] && this.$refs[k].$attrs['data-open']) {
					this.$refs[k].$el.click();
				}
			});
		},
		createSurveyTemplate () {
			this.manageMentalHealthDialog = true;
			this.update = false;
		},
		showDeleteConfirmationDialog (template) {
			this.templateToDelete = template;
			this.deleteConfirmationDialog = true;
		},
		closeDeleteConfirmationDialog () {
			this.deleteConfirmationDialog = false;
			this.templateToDelete = null;
		},
		deleteSurveyTemplateAndCloseDialog () {
			this.deleteSurveyTemplate(this.templateToDelete);
			this.closeDeleteConfirmationDialog();
		},
		deleteSurveyTemplate (item) {
			this.$store.dispatch('mentalhealth/deleteSurveyTemplateByIdV2', item.id).then((res) => {
				if (res.resFlag) {
					showSuccessAlert(this.$t('success'));
					this.loadData();
				} else {
					showErrorAlert(this.$t('failed'));
				}
			});
		},
		editSurveyTemplate (item) {
			this.itemForEdit = item;
			this.manageMentalHealthDialog = true;
			this.update = true;
			this.$store.dispatch('mentalhealth/getSurveyTemplateById', item.id);
		},
		loadData () {
			const hospitalId = this.userData.hospitalAssignedData.id;
			this.$store.dispatch('mentalhealth/getMentalHealthSurveyTemplates', hospitalId).then(() => {
				// this.closeAll();
			});
		},
	},
	mounted () {
		this.loadData();
		this.closeAll();
	},
};
</script>
<style scoped>
</style>
