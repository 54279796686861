<template>
  <v-dialog
      v-model="dialog"
      :width="surveyCreate ? `90%` : `40%`"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      dense
      class="dialog-style"
  >
    <v-card class="overflow-hidden">
      <v-card-title class="secondary py-1" style="height: 40px">
        <span class="dialog-title">{{ $t("mentalHealthTemplate") }}</span>
        <v-spacer />
        <v-icon small color="primary" @click="closeDialog">mdi-window-close</v-icon>
      </v-card-title>
      <v-card-text style="padding:10px;" >
        <v-row>
          <v-col>
            <v-row class="my-1">
              <v-text-field @blur="editSurvey" :label="this.$t('name')" dense hide-details v-model="survey.templateName" outlined style="height:40px; margin-left:12px; margin-right:12px;"></v-text-field>
<!--              <v-autocomplete @blur="editSurvey" hide-details :label="this.$t('type')" :items="mentalHealthCategory" v-model="survey.mentalHealthCategoryId" dense :item-text="this.$i18n.locale === 'en' ? `shortDescription` : `shortDescriptionEsp`" item-value="lowValue" style="height:40px; margin-left:12px; margin-right:12px;" outlined></v-autocomplete>-->
            </v-row>
            <v-row>
              <v-text-field @blur="editSurvey" :label="this.$t('description')" dense hide-details v-model="survey.templateDescription" outlined style=" margin-left:12px; margin-right:12px;"></v-text-field>
            </v-row>
            <v-row class="d-flex justify-end px-3 py-2" style="overflow:hidden">
              <v-btn v-if="!surveyCreate" class="table-cancel-button" @click="closeDialog" style="margin-right:5px;"><span>{{ $t("cancel") }}</span></v-btn>
              <v-btn v-if="!surveyCreate" class="table-create-button" @click="saveSurvey"><span>{{ $t("save") }}</span></v-btn>
            </v-row>
          </v-col>
          <v-col class="col-12" v-if="surveyCreate || update" style="height: 700px; overflow:auto;">
            <draggable :list="questions" @end="(event) => endQuestionsDrag(event, questions)">
              <v-card class="elevation-4" v-for="(question, index) in questions" :key="index">
              <v-row class="my-2 mx-5 py-4 d-flex align-center">
                  <v-icon small color="primary">mdi-arrow-all</v-icon>
                  <v-avatar class="mx-2" color="primary" size="20"><span style="color: white">{{index + 1}}</span>
                  </v-avatar>
                  <v-text-field
                      hide-details dense
                      style="font-size: 15px;"
                      @change="updateQuestion(question, index)"
                      v-model="question.name"
                      @keyup="submit"
                      @keyup.enter="createQuestion(index, question)"
                  >
                    <template v-slot:append>
                      <v-icon
                          v-if="hideButton[index] === true"
                          color="primary"
                          :disabled="temp"
                          @click="createQuestion(index, question)"
                      >mdi-plus
                      </v-icon>
                      <v-icon @click="deleteQuestion(question, index)" color="primary">mdi mdi-delete</v-icon>
                    </template>
                  </v-text-field>
              </v-row>
              <v-radio-group
                  row
                  hide-details
                  dense
                  v-model="question.type"
                  style="padding:0px !important; margin:10px !important;"
                  @change="updateQuestion(question, index)"
              >
                <v-radio
                    v-for="(verifiers, index) in surveyAnswerType" :key="index"
                    :label="$i18n.locale === 'en' ? verifiers.shortDescription : verifiers.shortDescriptionEsp"
                    :value="verifiers.lowValue"
                ></v-radio>
                <span v-if="hideButton[index] === true && !temp"></span>
              </v-radio-group>
              <!-- SINGLE CHOICE -->
              <div v-if="question.type === 1 && hideAnswers[index] === true">
                <div>
                  <v-row>
                    <v-col>
                      <v-data-table
                          :headers="headers"
                          :items="questions[index].options"
                          :no-data-text="$t('no-option-entered')"
                          :items-per-page="-1"
                          dense
                          hide-default-footer
                          class="elevation-3 mb-2 font-bold"
                      >
                        <template v-slot:body="props">
                          <draggable :list="props.items" tag="tbody" @end="(event) => endDrag(event, props.items)">
                            <tr v-for="(item, index) in props.items" :key="index">
                              <td style="text-align: left">
                                <v-icon small color="primary">mdi-arrow-all</v-icon>
                              </td>
                              <td>
                                {{ item.orderNumber }}
                              </td>
                              <td style="text-align: right !important;">
                                <div style="display: flex; justify-content: flex-end; align-items: center;">
                                  <v-checkbox hide-details dense v-model="item.triggerNotification" :value="item.triggerNotification" color="primary" @change="editAnswerRow(item)" class="mr-8"></v-checkbox>
                                </div>
                              </td>
                              <td>
                                <v-text-field v-model="item.possibleAnswer" :value="item.possibleAnswer" hide-details dense @blur="editAnswerRow(item)" flat solo></v-text-field>
                              </td>
                              <td>
                                  <v-text-field v-model="item.scorePoints" :value="item.scorePoints" hide-details dense @blur="editAnswerRow(item)" flat solo></v-text-field>
                              </td>
                              <td>
                                <v-icon small style="color:red;" @click="deleteItem(item, question.id)">mdi-delete</v-icon>
                              </td>
                            </tr>
                          </draggable>
                        </template>
                      </v-data-table>
                      <v-card dense >
                        <v-card-text style="padding-bottom:0px !important;">
                          <v-text-field
                              hide-details
                              v-model="answer"
                              @input="isInputEmpty"
                              style="font-size:17.5px;"
                              :label="$t('possible-answers')"
                              @keyup.enter="addPossibleAnswer(index, newAnswer[index], questions)"
                              dense
                          >
                            <template v-slot:append>
                              <v-icon
                                  dense
                                  class="mr-2"
                                  color="primary"
                                  outlined
                                  :disabled="addAnswerButton"
                                  @click="addPossibleAnswer(index, newAnswer[index], questions)"
                              >
                                mdi-plus
                              </v-icon>
                            </template>
                          </v-text-field>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <!--MULTIPLE CHOICE QUESTION = 2 -->
              <div v-if="question.type === 2 && hideAnswers[index] === true">
                <div>
                  <v-row>
                    <v-col>
                      <v-data-table
                          :headers="headers"
                          :items="questions[index].options"
                          :no-data-text="$t('no-option-entered')"
                          :items-per-page="-1"
                          dense
                          hide-default-footer
                          class="elevation-3 mb-2 font-bold"

                      >
                        <template v-slot:body="props">
                          <draggable :list="props.items" tag="tbody" @end="(event) => endDrag(event, props.items)">
                            <tr v-for="(item, index) in props.items" :key="index">
                              <td style="text-align: left">
                                <v-icon small color="primary">mdi-arrow-all</v-icon>
                              </td>
                              <td>
                                {{ item.orderNumber }}
                              </td>
                              <td>
                                <div style="display: flex; justify-content: flex-end; align-items: center;">
                                  <v-checkbox hide-details dense v-model="item.triggerNotification" :value="item.triggerNotification" color="primary" @change="editAnswerRow(item)" class="mr-8"></v-checkbox>
                                </div>
                              </td>
                            <td>
                              <v-text-field v-model="item.possibleAnswer" :value="item.possibleAnswer" hide-details dense @blur="editAnswerRow(item)" flat solo></v-text-field>
                            </td>
                              <td>
                                <v-text-field v-model="item.scorePoints" :value="item.scorePoints" hide-details dense @blur="editAnswerRow(item)" flat solo></v-text-field>
                              </td>
                              <td>
                                <v-icon small style="color:red;" @click="deleteItem(item, question.id)">mdi-delete</v-icon>
                              </td>
                            </tr>
                          </draggable>
                        </template>
                      </v-data-table>
                      <v-card class="elevation-0" dense >
                        <v-card-text style="padding-bottom:0px !important;">
                            <v-text-field
                                hide-details
                                v-model="answer"
                                @input="isInputEmpty"
                                style="font-size:17.5px;"
                                :label="$t('possible-answers')"
                                @keyup.enter="addPossibleAnswer(index, newAnswer[index], questions)"
                                dense
                            >
                              <template v-slot:append>
                                <v-icon
                                    dense
                                    class="mr-2"
                                    color="primary"
                                    outlined
                                    :disabled="addAnswerButton"
                                    @click="addPossibleAnswer(index, newAnswer[index], questions)"
                                >
                                  mdi-plus
                                </v-icon>
                              </template>
                            </v-text-field>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <br />
            </v-card>
            </draggable>
            <div style="display:flex; justify-content: center; align-items: center;" >
            <span v-if="disableAddQuestion === true">
                <v-btn @click="addQuestion()"  style="background-color: white !important; margin-top:20px;" elevation="0">
                <v-avatar
                    color="primary"
                    size="35"
                ><v-icon style="color: white">mdi-plus</v-icon>
                </v-avatar>
              </v-btn>
              </span>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import draggable from 'vuedraggable';
import { mapState } from 'vuex';
export default {
	props: ['dialog', 'update', 'surveyAnswerType', 'mentalHealthCategory'],
	components: {
		draggable,
	},
	data () {
		return {
			addAnswerButton: true,
			answer: null,
			answerId: {},
			createdQuestion: false,
			hideButton: [true],
			checkbox: false,
			notifyAnswer: false,
			questionDelete: true,
			disableAddAnswer: true,
			indexQuestion: null,
			disableAddQuestion: false,
			newIndex: null,
			surveyCreate: false,
			selected: [],
			hideAnswers: [],
			temp: true,
			surveyId: null,
			survey: {
				templateName: '',
				// mentalHealthCategoryId: '',
				templateDescription: '',
				hospitalId: null,
			},
			newAnswer: [],
			itemForEdit: {},
			title: 1,
			editedItem: {
				name: '',
				calories: 0,
				fat: 0,
				carbs: 0,
				protein: 0,
			},
			defaultItem: {
				name: '',
				calories: 0,
				fat: 0,
				carbs: 0,
				protein: 0,
			},
			questions: [
				{
					name: '',
					type: 3,
					options: [],
				},
			],
		};
	},
	computed: {
		...mapState({
			verifiers: (state) => state.verifiers.verifiers,
			surveyById: (state) => state.mentalhealth.surveyTemplate,
			userData: (state) => state.authentication.userData,
		}),
		headers () {
			return [
				{ text: 'Order', sortable: false },
				{ text: '', value: 'orders', sortable: false },
				{ text: 'Notification', value: 'notification', sortable: false, align: 'center' },
				{ text: 'Possible Answer', value: 'checkbox', width: '85%', sortable: false },
				{ text: 'Points', value: 'checkbox', width: '5%', align: 'center', sortable: false },
				{ text: '', sortable: false },
			];
		},
	},

	watch: {
		'survey.templateName': function () {
			this.surveyChanged = true;
		},
		// 'survey.mentalHealthCategoryId': function () {
		// 	this.surveyChanged = true;
		// },
		'survey.templateDescription': function () {
			this.surveyChanged = true;
		},
		newAnswer (val) {
			if (val[this.newIndex] === '') {
				this.disableAddAnswer = true;
			} else {
				this.disableAddAnswer = false;
			}
		},
		checkbox (val) {
			this.notifyAnswer = val;
		},
		surveyById (val) {
			if (this.update) {
				this.surveyCreate = true;
				this.disableAddQuestion = true;
				for (let i = 0; i < val.questionsListWithAnswers.length; i++) {
					this.hideButton[i] = false;
				}
				this.disableAddQuestion = true;
				this.survey = {
					templateName: val.templateName,
					// mentalHealthCategoryId: val.mentalHealthCategoryId,
					templateDescription: val.templateDescription,
					hospitalId: val.hospitalId,
				};
				this.hideAnswers = [];
				this.questions.push(
					{
						name: '',
						type: '',
						options: [],
						id: null,
					},
				);
				for (let i = 0; i < val.questionsListWithAnswers.length; i++) {
					this.hideAnswers.push(true);
				}
				this.getAllSelectedAnswers();
				this.surveyId = val.id;
				var option = [];
				this.questions = [];
				val.questionsListWithAnswers.forEach(item => {
					item.offeredAnswersList.forEach(element => {
						option.push(element);
					});
					var tempQuestion = {
						name: item.question,
						type: item.answerTypeId,
						options: option,
						id: item.id,
					};
					option = [];
					this.questions.push(tempQuestion);
				});
			} else if (!this.update && this.title !== 1) {
				this.hideAnswers.push(false);
			}
		},
		dialog (val) {
			val || this.close();
		},
	},
	methods: {
		editAnswerRow (item) {
			const object = {
				stQuestionId: item.stQuestionId,
				possibleAnswer: item.possibleAnswer,
				orderNumber: item.orderNumber,
				id: item.id,
				triggerNotification: item.triggerNotification,
				scorePoints: item.scorePoints === '' ? null : item.scorePoints,
			};
			// Dispatch the update API call
			this.$store.dispatch('mentalhealth/updateAnswer', object);
		},
		endQuestionsDrag (event, items) {
			const questionId = items[event.newDraggableIndex].id;
			const orderId = event.newDraggableIndex + 1;

			const updatedQuestion = {
				StQuestionId: questionId,
				ChangedOrderNumberValue: orderId,
			};
			this.$store.dispatch('mentalhealth/stquestionReorder', updatedQuestion);
		},
		endDrag (event, items) {
			const itemId = items[event.newDraggableIndex].id;
			const orderId = event.newDraggableIndex + 1;

			// Update the order of items in the items array
			items.forEach((item, index) => {
				item.orderNumber = index + 1;
			});

			const updatedAnswer = {
				stOfferedAnswerId: itemId,
				changedOrderNumberValue: orderId,
			};

			// Call the API only once for the moved item
			this.$store.dispatch('mentalhealth/stofferAnswerReorder', updatedAnswer);
		},
		isInputEmpty () {
			this.addAnswerButton = false;
		},
		async editSurvey () {
			if (this.surveyCreate) {
				const body = {
					id: this.surveyId,
					templateName: this.survey.templateName,
					templateDescription: this.survey.templateDescription,
					// mentalHealthCategoryId: this.survey.mentalHealthCategoryId,
					hospitalId: this.survey.hospitalId,
				};
				await this.$store.dispatch('mentalhealth/editSurvey', body).then(res => {
					if (res.resFlag) {
						this.$store.commit('alerts/add', {
							type: 'success',
							message: res.msg,
						});
						this.$store.commit('alerts/clear', {
							type: 'success',
							message: res.msg,
						});
					} else {
						this.$store.commit('alerts/add', {
							type: 'alert',
							message: res.msg,
						});
						this.$store.commit('alerts/clear', {
							type: 'alert',
						});
					}
				});
			}
		},
		submit () {
			if (this.questions[0].name !== '') {
				this.temp = false;
			} else {
				this.temp = true;
			}
		},
		closeDialog () {
			this.temp = true;
			this.createdQuestion = false;
			this.survey = {
				templateName: '',
				// mentalHealthCategoryId: '',
				templateDescription: '',
				hospitalId: null,
			};
			this.$emit('close');
			this.$emit('refresh-surveys');
			this.surveyCreate = false;
		},
		createQuestion (index, question) {
			this.createdQuestion = true;
			this.questionDelete = false;
			if (question.type === 3) {
				this.disableAddQuestion = true;
			}
			this.indexQuestion = index;
			this.hideButton[index] = false;
			var object = {
				surveyTemplateId: this.surveyId,
				question: this.questions[this.questions.length - 1].name,
				answerTypeId: this.questions[this.questions.length - 1].type,
				orderNumber: this.questions.length,
				isMandatory: true,
			};
			this.$store.dispatch('mentalhealth/createQuestion', object).then(res => {
				this.surveyCreate = true;
				this.questions[this.questions.length - 1].id = res.id;
			});
			this.hideAnswers.push(true);
		},
		deleteQuestion (item, index) {
			if (item.id !== null) {
				this.questionDelete = false;
				this.hideButton.splice(index, 1);
				this.indexQuestion = index;
				this.$store.dispatch('mentalhealth/deleteQuestion', item.id).then(() => {
					if (this.update) {
						this.$emit('update-survey');
					} else {
						this.$store.dispatch('mentalhealth/getSurveyTemplateById', this.surveyId);
					}
				});
			} else {
				this.questionDelete = true;
				this.disableAddQuestion = true;
				this.hideButton.splice(index, 1);
				this.indexQuestion = index;
				this.questions.splice(index, 1);
			}
		},
		getAllSelectedAnswers () {
			this.selected = [];
			this.surveyById.questionsListWithAnswers.forEach((item) => {
				item.offeredAnswersList.forEach((element) => {
					if (element.triggerNotification) {
						this.selected.push(element.id);
					}
				});
			});
		},
		updateQuestion (question, index) {
			if (question.id !== null && question.id !== undefined) {
				const object = {
					question: question.name,
					answerTypeId: question.type,
					orderNumber: index + 1,
					isMandatory: true,
					id: question.id,
				};
				this.$store.dispatch('mentalhealth/updateQuestion', object);
			}
		},
		editItem (item, index) {
			this.disableAddAnswer = true;
			this.newIndex = index;
			this.title = 2;
			this.itemForEdit = {
				item: item,
				index: index,
			};
		},
		async saveSurvey () {
			this.disableAddQuestion = false;
			this.hideButton = [true];
			this.hideAnswers = [];
			this.questions = [
				{
					name: '',
					type: 3,
					options: [],
					id: null,
				},
			];
			this.survey.hospitalId = this.userData.hospitalAssignedData.id; // we get the hospitalId from store
			await this.$store.dispatch('mentalhealth/createSurveyTemplate', this.survey).then(res => {
				this.surveyId = res.id;
			});
			this.surveyCreate = true;
		},
		deleteItem (item, questionId) {
			// Find the question by ID
			const question = this.questions.find(q => q.id === questionId);

			if (question) {
				// Filter out the item from the options array of the found question
				question.options = question.options.filter(element => element.possibleAnswer !== item.possibleAnswer);
				// Dispatch the delete action
				this.$store.dispatch('mentalhealth/deleteAnswer', item.id);
			}
		},
		close () {
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
			});
		},
		addPossibleAnswer (index, answer, question) {
			this.disableAddQuestion = true;

			if (this.title === 1) {
				this.handleCreateMode(index, question);
			} else if (this.title === 2) {
				this.handleEditMode(index, question);
			}
		},

		handleCreateMode (index, question) {
			const orderNumber = question[index].options.length + 1;
			question[index].options.push({ name: this.newAnswer[index], id: orderNumber });

			const createAnswer = {
				stQuestionId: question[index].id,
				possibleAnswer: this.answer,
				orderNumber: orderNumber,
				triggerNotification: this.notifyAnswer,
			};

			this.$store.dispatch('mentalhealth/createAnswer', createAnswer).then(res => {
				question[index].options.pop();
				question[index].options.push(res.data);
				if (res.data.triggerNotification) {
					this.selected.push(res.data.id);
				}
				this.surveyCreate = true;
			});
			this.checkbox = false;
			this.newAnswer[this.indexQuestion] = '';
			this.answer = '';
		},
		handleEditMode (index, question) {
			const targetQuestion = question[index];

			if (targetQuestion && Array.isArray(targetQuestion.options)) {
				const answerId = targetQuestion.options.find(element => element.id === this.itemForEdit.item.id);

				if (answerId) {
					const object = {
						stQuestionId: targetQuestion.id,
						possibleAnswer: this.answer,
						orderNumber: answerId.orderNumber,
						id: answerId.id,
						triggerNotification: this.notifyAnswer,
					};

					// Dispatch the update API call
					this.$store.dispatch('mentalhealth/updateAnswer', object).then(res => {
						if (res.statusCode === 201) {
							// Update the options array with the edited answer
							const editedAnswerIndex = question[index].options.findIndex(option => option.id === answerId.id);
							if (editedAnswerIndex !== -1) {
								question[index].options.splice(editedAnswerIndex, 1, res.data);
							}
						}
						// Clear fields and reset the mode
						this.newAnswer = [];
						this.title = 1;
						this.checkbox = false;
						this.newAnswer[index] = '';
					});
				}
			}
		},
		async addQuestion () {
			this.createdQuestion = false;
			this.temp = true;
			if (!this.questionDelete) {
				this.hideButton[this.hideButton.length] = true;
			} else if (this.questionDelete) {
				this.hideButton[this.hideButton.length] = true;
			}
			this.disableAddQuestion = false;
			this.questions.push({
				type: 3,
				name: '',
				options: [],
				id: null,
			});
		},
	},
	mounted () {
		this.submit();
	},
};
</script>
